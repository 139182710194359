const _file = 'CountryProvinceSelect';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var CountryProvinceSelect = (function($){
  function setProvinceSelect($wrapper, $country_elem) {
    var $parking = $('[data-province-parking-for="' + $wrapper.data('province-field-for') + '"]');
    if ($parking.length == 0) { return; }
    var $prov_select = $parking.find('[data-prereq-country="' + $country_elem.val() + '"]');
    var $prov_cur    = $wrapper.children(0);
    if ($prov_select.length > 0) {
      $parking.append($prov_cur);
      $wrapper.append($prov_select);
    }
    else if (!$prov_cur.is('[data-prereq-country="' + $country_elem.val() + '"]')) {
      $parking.append($prov_cur);
      $wrapper.append($parking.find('[data-prereq-country="default"]'));
    }
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.province-wrapper').each(function() {
      var $wrapper = $(this);
      var $country_elem = $($wrapper.data('country-selector'));
      setProvinceSelect($wrapper, $country_elem);

      $country_elem.change(function(e) {
        // If country is hidden, ensure no changes are made to prov/state dropdown on "change"
        // This prevents browser autofill from affecting the hidden country field
        var country_hidden = $country_elem.parent().is(".d-none");

        if (!country_hidden) {
          setProvinceSelect($wrapper, $(this));
        }
      });
    });
  });

  // Return API for other modules
  return {};
})(jQuery);