import GlassDimmer from 'glass/modules/dimmer'

const _file = 'GlobalNav';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlobalNav = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find(".global-nav-trigger").click(function() {
      // add transition only after link click, this fixes the sidebar flicker on page load
      $(".floating-menu").addClass("transition");

      if ($(".floating-menu").hasClass('active')) {
        hideSidebar();
      }
      else {
        showSidebar();
      }
    })
  });

  function showSidebar() {
    $(".global-nav-btn").addClass("d-none");
    $(".bars-toggle").removeClass("collapsed");
    $(".floating-menu").addClass("active");
    $('html').addClass('no-scroll');
    GlassDimmer.dim();
  }

  function hideSidebar() {
    $(".global-nav-btn").removeClass("d-none");
    $(".bars-toggle").addClass("collapsed");
    $(".floating-menu").removeClass("active");
    $('html').removeClass('no-scroll');
    GlassDimmer.unDim();
  }

  GlassDimmer.onDimmerClick(function() {
    var $sidebar = $('.floating-menu.active');
    if ($sidebar.length > 0) {
      hideSidebar();
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
