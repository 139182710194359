import GlassScroll from 'glass/modules/scroll'

const _file = 'TabGroupLinks';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var TabGroupLinks = (function($){

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var tab_selector = '.tab-group-tabs a.nav-link';
    var $tabs = $(element).find(tab_selector);
    if ($tabs.length > 0) {
      var hash = document.location.hash;
      // Using a suffix in a similar way a prefix is used here: https://stackoverflow.com/a/10787789
      //   to fix a scroll issue. But we're using the inverse of the SO example to keep "-tab" out of the url.
      var suffix = "-tab";
      var $this_tab;
      if (hash) {
        $this_tab = $('.tab-group-tabs a[href="' + hash + suffix + '"]');
        $this_tab.tab('show');
      } else {
        $this_tab = $('.tab-group-tabs a.active');
      }

      // Change hash for page-reload
      $(tab_selector).on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash.replace(suffix, "");
      });

      $(tab_selector).on('click', function(e) {
        e.preventDefault();
      });
    }

    var accordion_selector = '.tab-group-accordion .collapse';
    var $accordions = $(element).find(accordion_selector);
    if ($accordions.length > 0) {
      var collapse_hash = document.location.hash;
      // Using a suffix in a similar way a prefix is used here: https://stackoverflow.com/a/10787789
      //   to fix a scroll issue. But we're using the inverse of the SO example to keep "-tab" out of the url.
      var collapse_suffix = "-tab";
      var $this_collapse;
      if (collapse_hash) {
        $this_collapse = $(collapse_hash + collapse_suffix);
        $this_collapse.collapse('show');
        GlassScroll.scrollTo($this_collapse);
      }

      $(accordion_selector).on('shown.bs.collapse', function(e) {
        window.location.hash = e.target.id.replace(collapse_suffix, "");
      });
    }
  });

  // Return API for other modules
  return {
  };
})(jQuery);
