import Glide from '@glidejs/glide'

const _file = 'GlassGlide';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassGlide = (function ($) {
  var fully_loaded = false;
  var $glide_carousels = $([]);

  function initGlideCarousels() {
    if ($glide_carousels.length > 0 && fully_loaded) {
      var default_options = {
        type:                 'carousel',
        // perView:              3, # want to set everytime
        focusAt:              'center',
        gap:                  30,
        autoplay:             false,
        keyboard:             true,
        dragThreshold:        35,
        touchThreshold:       30,
        perTouch:             false,
        touchRatio:           0.65,
        animationTimingFunc:  'cubic-bezier(0, 0, 0.250, 1.000)',
        // peek:                 100, # want to set everytime
        // breakpoints:          {}, # want to set everytime
      };

      // const LengthValueCustom = function(Glide, Components, Events) {
      //   return {
      //     mount () {
      //       Events.emit('slider.length', Components.Sizes.length)
      //     }
      //   }
      // };

      $glide_carousels.each(function() {
        var custom_options = $(this).data('options');
        var hideLeftArrowOnInit = custom_options.hideLeftArrowOnInit ? custom_options.hideLeftArrowOnInit : false;
        delete custom_options.hideLeftArrowOnInit;
        var options = $.extend({}, default_options, custom_options);

        const slider = new Glide(this, options);
        // let leftArrowClicks = 0;
        // let rightArrowClicks = 0;
        // let slidesLength = 0;

        // slider.on('slider.length', length => {
        //   slidesLength = length;
        // })

        if (hideLeftArrowOnInit) {
          slider.on("run", () => {
            $(this).find(".glide__arrow--left").removeClass("arrow-hidden");
          });
        };


        // // Custom right arrow click handler
        // $(this).find(".glide__arrow--right").on("click touchstart", () => {
        //   (rightArrowClicks <= slidesLength - 1) ? rightArrowClicks++ : rightArrowClicks = 0;

        //   setTimeout(()=> {
        //     slider.go(`=${rightArrowClicks}`);
        //   }, 50)
        // });

        // // Custom left arrow click handler
        // $(this).find(".glide__arrow--left").on("click touchstart", () => {
        //   (leftArrowClicks > -slidesLength) ? leftArrowClicks-- : leftArrowClicks = 0;

        //   setTimeout(()=> {
        //     slider.go(`=${leftArrowClicks}`);
        //   }, 50)
        // });

        // slider.mount({LengthValueCustom});
        slider.mount();
      });

      $glide_carousels = $([]); // global variable, reset it now that they've been initialized
    }
  }

  window.addEventListener('load', () => {
    fully_loaded = true;
    initGlideCarousels();
  });

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $glide_carousels = $glide_carousels.add($(element).find('.glide'));
    initGlideCarousels();
  });

  // Return API for other modules
  return {
  };
})(jQuery);

export default GlassGlide
