/**
 * To wrap your content in a shadow dom, add a `data-shadow="true"` to the element
 * you want as the shadow root.
 * All of its content will be placed inside of the shadow dom
 */
function wrapShadowDom() {
  var shadowDomCandidates = document.querySelectorAll("[data-shadow='true']");
  shadowDomCandidates.forEach((shadowDomCandidate) => {
    shadowDomCandidate.attachShadow({ mode: "open" });
    shadowDomCandidate.shadowRoot.innerHTML = shadowDomCandidate.innerHTML;
    shadowDomCandidate.innerHTML = "";
  });
}

document.addEventListener("DOMContentLoaded", wrapShadowDom);
