import Cookies from 'js-cookie'

const _file = 'EventShow';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var EventShow = (function($){
  var $form;

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var form_id = $("#register-form-id").data("form-id");

    if (form_id) {
      var cookie_name = "form-" + form_id + "-event-selected";

      // Set event cookie (for redirect from login/signup) from query param
      var event_query_param = new URLSearchParams(window.location.search).get(
        "selected_event"
      );
      if (event_query_param) {
        Cookies.set(cookie_name, event_query_param);
      }

      var $_form = $(element).find("form.formbuilder-form");
      if (
        $_form.length > 0 &&
        $_form.parents(
          `.builtform-module[data-form-id="${form_id || "not-found"}"]`
        ).length > 0
      ) {
        $form = $_form; // found a form within element (ajax'ed in & added to the DOM), save it globally

        // Select event from cookie (for redirect from login/signup) or from anchor param (for clicking link with anchor)
        var selected_event = Cookies.get(cookie_name);

        var hash = document.location.hash;
        if (hash && hash.includes("event-")) {
          selected_event = hash.replace("event-", "");
        }

        if (selected_event) {
          Cookies.set(cookie_name, selected_event);
          select_event($form, selected_event);
        } else {
          select_event($form, $("#register-form-id").data("event-id")); // main event showing - standalone or parent event
        }

        // Update register btns on register form submit
        $form.on(
          "form-submit-success",
          function (e, response, statusText, xhr, element) {
            $(".event-register-btn.btn-follow").addClass("active");
          }
        );
      }
    }

    // Trigger tab change
    $(element).find('.event-register-btn').click(function (e) {
      var tab_href = $(this).attr('href');
      if (tab_href != "" && tab_href.match(/#register.*-tab/g)) {
        e.preventDefault();
        var event_id = $(this).data('event-id');
        Cookies.set(cookie_name, event_id);
        if ($form?.length > 0) {
          select_event($form, event_id);
        }
        $('.tab-group-tabs a[href="' + tab_href + '"]').tab('show');
      }
    });
  })

  function select_event($form, event_id) {
    $form.find('input.form-event-id').val(event_id).change();
  }

  // Return API for other modules
  return {
  }
})(jQuery)