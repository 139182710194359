import Cookies from 'cookies/js.cookie'

const _file = 'AutoCurateGuidCookies';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var AutoCurateGuidCookies = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var DEBUG = !production;
    function debug_log() {
      if (DEBUG) {
        var color = "#3498db";
        console.log.apply(this, [
          `%cAutoCurate`,
          `background: ${color}; color: white; padding: 2px 3px; border-radius: 2px; font-size: 0.8em;`,
          arguments[0],
        ]);
      }
    }

    var COOKIES_DISABLED = !navigator.cookieEnabled;
    if (COOKIES_DISABLED) {
      element.querySelectorAll('.please-enable-cookies-warning').forEach(el => el.classList.remove('d-none'));
    }

    var $cookie_elem = $(element).find('#auto-curate-cookies');
    if ($cookie_elem.length == 0 || COOKIES_DISABLED) {
      return;
    }

    var currentLocation = window.location.href;
    var originParam = "?origin=" + btoa(currentLocation);
    var guid = Cookies.get('device_guid');

    // Stop if we already have the guid cookie
    if (guid) {
      if ($cookie_elem.hasClass("delay-view-count")) {
        debug_log("Have a guid cookie, now trigger the delayed view-count.")
        $cookie_elem.trigger('send-view-count');
      }
      else {
        debug_log("Have a guid cookie");
      }
      return;
    }

    debug_log("No guid cookie on this domain. Checking if the cookie domain has the device_guid cookie set...");

    if ($cookie_elem.data('cookie-domain')) {
      get_guid_external_domain();
    } else {
      set_cookie_current_domain();
    }

    function get_guid_external_domain() {
      $.ajax({
        url: $cookie_elem.data('cookie-domain') + $cookie_elem.data('get-guid-path'),
        type: 'GET',
        dataType: 'json',
        xhrFields: {
          withCredentials: true
        },
        success: function(data, textStatus, request) {
          guid = data.guid;
          debug_log("Is the device_guid cookie set on the cookie domain? " + (guid ? 'Yep!' : 'No!') + " -- " + (guid || ''));

          if (!guid) {
            set_cookie_external_domain();
          } else {
            set_cookie_current_domain();
          }
        },
        error: function(data, textStatus, error) {
          debug_log("Whoops, that's an error.");
          debug_log(error);
          guid = null;
        }
      });
    }

    function set_cookie_current_domain() {
      var set_cookie_path = $cookie_elem.data('set-cookie-path');
      debug_log(`Redirecting to set_cookie on the current domain... (${set_cookie_path} then back to ${currentLocation})`);
      window.location.replace(window.location.origin + set_cookie_path + originParam + "&guid=" + guid);
    }

    function set_cookie_external_domain() {
      var set_cookie_url     = $cookie_elem.data('cookie-domain') + $cookie_elem.data('set-cookie-path');
      var set_cookie_url_cur = window.location.origin + $cookie_elem.data('set-cookie-path')
      debug_log(`Redirecting to set_cookie on the cookie domain then current domain... (${set_cookie_url} then ${set_cookie_url_cur} then back to ${currentLocation})`);
      window.location.replace(set_cookie_url + originParam + "&return_set_cookie=" + set_cookie_url_cur);
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
