const _file = 'BlogViewCount';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var BlogViewCount = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $view_count_elem = $(element).find('[data-view-count-url]');
    var $delay_elem = $(element).find('.delay-view-count');
    if ($view_count_elem.length > 0) {
      count_once_wrapper($delay_elem, $view_count_elem, 'views');

      $(document).on('scroll-progress', function (e, percent) {
        var t1 = $view_count_elem.data('scroll-30-time');
        if (!t1 && percent > 30) {
          $view_count_elem.data('scroll-30-time', Date.now());
        }

        if (t1 && percent > 80 && (Date.now() - t1) > 30000) {
          count_once_wrapper($delay_elem, $view_count_elem, 'reads');
        }
      });
    }

    $(element).find('[data-click-count-url]').on('click', function(e) {
      count_once($(this), 'clicks');
    });
  });

  function count_once_wrapper($delay_elem, $view_count_elem, action) {
    if ($delay_elem.length > 0) { // this delay is to get/init the cookie first
      $delay_elem.on('send-view-count', function() {
        count_once($view_count_elem, action);
      });
    } else {
      count_once($view_count_elem, action);
    }
  }

  function count_once($view_count_elem, action) {
    if (!$view_count_elem.data('counted-' + action)) {
      $view_count_elem.data('counted-' + action, true);

      $.ajax({
        url: $view_count_elem.data('view-count-url') || $view_count_elem.data('click-count-url'),
        type: 'PATCH',
        data: {count: action},
        xhrFields: {
          withCredentials: true
        },
        success: function(result) {
        }
      });
    }
  }

  //Return API for other modules
  return {};
})(jQuery);
