import GlassSearch      from 'glass/modules/search'

const _file = 'FilterSort';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var FilterSort = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var container_selector = '.resources-index-container';

    if ($(container_selector).length == 0) {
      return
    }

    $(element).find('.search-form .search-btn').each(function() {
      // NOTE: .resources-index-container is replaced via ajax
      //       .resources-index-inner is hidden during loading (replaced with spinner)
      var $search_btn        = $(this);
      var $container = $search_btn.parents(container_selector).first();

      if ($container.length == 0) {
        $container = $('#search-results-modal ' + container_selector).first();
      }

      $search_btn.data('callback-on-trigger', function() {
        var $modal = $container.parents('#search-results-modal');

        if ($modal.length > 0) {
          $modal.fadeIn();
          $('body').addClass('modal-open search-modal-open');
        }

        $container.find('.resources-index-inner').html('');
        $('.pagination-spinner').children().show();

        var $search_form = $search_btn.parents('.search-form');
        var query = $search_form.find('input[name="search"]').val();
        var url = $search_form.attr('action') + (query ? ('?search=' + query) : '');
        if (window.location.href.includes('?search=')) {
          window.history.replaceState({}, 'Search / Filter', url);
        } else {
          window.history.pushState({}, 'Search / Filter', url);
        }
      }.bind($search_btn));

      $search_btn.data('callback', function($search_btn) { return function(data) {
        $('.pagination-spinner').children().hide();
        var $new_container = $(data).find(container_selector).first();
        if ($new_container.length > 0) {
          $container.html($new_container.html());
          $(document).trigger('content-ready', $container);
        }
      }; }($search_btn));
    });


    $(element).find('#index-filters select').change(function(e) {
      var $form = $(this).parents(container_selector).find('.form-for-ajax-filtering');
      $form.attr('action', $(this).val());
      GlassSearch.perform_search($form.find('.search-btn'), false, false);
    });
  });

  //Return API for other modules
  return {};
})(jQuery);
