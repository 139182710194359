const _file = 'LayoutOptions';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var LayoutOptions = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $headings = $(element).find('.page-content #article-body').find('h2[id], h3[id], h4[id]');
    if ($headings.length > 0) {
      $headings.each(function() {
        $(this).attr('data-url', window.location.href + "#" + $(this).attr('id'));
        $(this).attr('data-toggle', "popover");
        $(this).attr('data-trigger', "hover");
        $(this).attr('data-placement', "left");
        $(this).attr('data-html', "true");
        $(this).attr('data-content', "<span class='content'><i class='icon icon-link mr-1'></i> Copy link</span>");
        $(this).attr('data-content-copied', "<i class='icon icon-check mr-1 green'></i> Copied");
        $(this).addClass('cp-share cursor-pointer');
      });
      $(document).trigger('content-ready', $(element).find('.page-content #article-body'));
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
