const _file = 'GlassShare';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassShare = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    function open_window(url) {
      var width  = 475,
          height = 300,
          left   = ($(window).width()  - width)  / 2,
          top    = ($(window).height() - height) / 2,
          opts   = 'status=1' +
                   ',width='  + width  +
                   ',height=' + height +
                   ',top='    + top    +
                   ',left='   + left;

      window.open(url, 'Share', opts);
    }

    $('.tw-share').click(function(e) {
      var twitter_url = "https://twitter.com/share";

      if ($(this).data('text')) {
        twitter_url += "?text=" + encodeURIComponent($(this).data('text'));
      }

      if ($(this).data('url')) {
        twitter_url += "&url=" + encodeURIComponent($(this).data('url'));
      }

      //console.log(twitter_url);

      open_window(twitter_url);
    });

    $('.fb-share').click(function(e) {
      var fb_url = "https://facebook.com/sharer/sharer.php?",
          page_url = $(this).data('url') || window.location.href;

      fb_url += "u=" + encodeURIComponent(page_url);

      if ($(this).data('text')) {
        fb_url += "&title=" + encodeURIComponent($(this).data('text'));
      }

      //console.log(fb_url);

      open_window(fb_url);
    });

    $('.em-share').click(function(e) {
      var subject = $(this).data('subject') || "Check this out!";
      var em_url = "mailto:?subject=" + encodeURIComponent(subject),
          page_url = $(this).data('url') || window.location.href;
      var body = $(this).data('text') || "";
          body += " " + page_url;

      em_url += "&body=" + encodeURIComponent(body);

      //console.log(em_url);

      window.open(em_url);
    });

    $('.sms-share').click(function(e) {
      var sms_url = "sms:&body=",
          page_url = $(this).data('url') || window.location.href;

      if ($(this).data('text')) {
        sms_url += encodeURIComponent($(this).data('text')) + " ";
      }

      sms_url += encodeURIComponent(page_url);

      window.open(sms_url);
    });

    if ($(element).find('.cp-share').length > 0) {
      try {
        var ClipboardJS = require('clipboard'), clipboard;

        clipboard = new ClipboardJS('.cp-share', {text: function(trigger){
          return trigger.getAttribute('data-url') || window.location.href;
        }});

        clipboard
          .on('success', function(e) {
            var $el, success_content, success_content_selector;

            if ($(e.trigger).data('toggle') == 'popover') {
              var popover = $(e.trigger).data('bs.popover');
              var $tip = $(popover.tip);
              var popover_visible = popover && $tip && $tip.is(':visible');
            }

            if ($(e.trigger).data('toggle') == 'popover' && popover_visible) {
              var copied_content = popover.config.contentCopied || "<i class='icon icon-check'></i>";
              $el = $tip.find('.popover-body').find('.content');
              success_content = "<span class='copied' style='display:none;'>" + copied_content + "</span>";
              success_content_selector = ".copied";
            } else {
              $el = $(e.trigger).find("[class*='icon']");
              success_content = "<i style='display:none;' class='icon icon-check'></i>";
              success_content_selector = ".icon-check";
            }

            $el.fadeOut(function(){
              $(success_content).insertBefore($el);
              $el.siblings(success_content_selector).fadeIn();
              setTimeout(function(){
                $el.siblings(success_content_selector).fadeOut(function(){
                  $el.fadeIn();
                  $el.siblings(success_content_selector).remove();
                });
              }, 2000);
            });
          })
          .on('error', function(e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
          });
      } catch (ex) {
        // Clipboard not available
      }
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
